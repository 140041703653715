import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const KanadaYa = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/kanada-ya/kanada-1.jpeg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/kanada-ya/kanada-2.jpeg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/kanada-ya/kanada-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "Kanada-Ya Central Gurney",
        "Kanada-Ya Central Gurney",
        "Kanada-Ya Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Kanada-Ya" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Kanada-Ya</h2>
                        <p className="font-light text-gray-600">Mon – Wed 10.30am – 10pm <br/> Thu – Sun  10.30am – 11pm</p>
                        <p className="font-light text-gray-600">For enquiries: 04-375 7284</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                            Kanada-Ya from Yukuhashi, Japan, is internationally recognized and voted as the Best Ramen in Fukuoka and as the No.1 Ramen Restaurant in London by The Telegraph London showcases authentic Tonkotsu Ramen, founded by Mr. Kazuhiro. With its great popularity, Kanada-Ya has expanded its market to 16 outlets worldwide including Japan, United Kingdom, Spain, Hong Kong & Singapore. This outlet in Penang will be the first outlet in Malaysia. Decked in warm and inviting tones, the restaurant exudes elegance in the form of exquisite Japanese art. Coupled with friendly and attentive service, dining at Kanada-Ya provides an authentic Japanese gastronomic experience amidst beautiful, contemporary surroundings.
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-5-kanada.jpg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default KanadaYa
